exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-certificaciones-tsx": () => import("./../../../src/pages/certificaciones.tsx" /* webpackChunkName: "component---src-pages-certificaciones-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-frescos-tsx": () => import("./../../../src/pages/frescos.tsx" /* webpackChunkName: "component---src-pages-frescos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-sustentable-tsx": () => import("./../../../src/pages/sustentable.tsx" /* webpackChunkName: "component---src-pages-sustentable-tsx" */)
}

